<template>
  <layout width="420">
    <template slot="header">
      Default user settings
    </template>

    <template>
      <v-form>
        <form-subheader title="General" />

        <v-select
          v-model="language"
          :items="languages"
          label="Default language"
          disabled
        />

        <form-subheader title="Map" />

        <form-switcher
          v-for="map in maps"
          :key="map.id"
          :value="!!getById('programAvailableMaps').defaultValue[map.id]"
          :title="map.name"
          @input="handleAvailableMaps(map.id, $event)"
        />

        <form-subheader title="Default position" />

        <v-text-field
          v-model="getById('programDefaultPosition').defaultValue[0]"
          type="number"
          label="Center latitude"
          clearable
        />
        <v-text-field
          v-model="getById('programDefaultPosition').defaultValue[1]"
          type="number"
          label="Center longitude"
          clearable
        />

        <form-subheader title="Default scale" />

        <v-slider
          v-model="getById('programDefaultScale').defaultValue"
          thumb-label
          max="28"
          min="0"
          type="number"
          append-icon="mdi-magnify-plus-outline"
          prepend-icon="mdi-magnify-minus-outline"
          @change="handleZoom"
        />
        <v-btn outlined color="primary" block @click="handleCurrent">
          Use current map parameters
        </v-btn>
        <form-subheader title="Customization" />
        <v-text-field v-model="state.appTitle" label="App title" clearable />
        <image-uploader
          :src="logoUrl"
          label="Company logo"
          @change="handleChangeLogo"
          @clear="handleClearLogo"
        />
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="close">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="false"
        @click="handleSubmit"
      >
        Save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';

import useGeneralSettings from '@/modules/settings/general/useGeneralSettings';
import { onMounted, ref, watch } from '@vue/composition-api';
export default {
  name: 'GeneralSettings',
  components: {
    Layout
  },
  setup(props, context) {
    const generalSettings = useGeneralSettings(props, context);
    const state = ref({
      appTitle: ''
    });

    watch(
      () => generalSettings.programSettings,
      () => {
        state.value.appTitle = generalSettings.getById(
          'programTitle'
        ).defaultValue;
      },
      { deep: true }
    );

    const handleSubmit = () => {
      generalSettings.getById('programTitle').defaultValue =
        state.value.appTitle;
      generalSettings.updateSettings();
    };
    return {
      ...generalSettings,
      handleSubmit,
      state
    };
  }
};
</script>
